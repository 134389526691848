import BaseLogo from 'components/Logo/Logo';
import styled from '@emotion/styled';

const Logo = styled(BaseLogo)({
  marginBottom: '0.8rem',
  marginLeft: '1.5rem',
  marginTop: '0.8rem',
});

export default Logo;
