import NavItems from './NavItems';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import {
  getContestsLink,
  getCustomRadioLink,
  getEventsLink,
  getFeaturesLink,
  getForYouLink,
  getGenresLink,
  getHomeLink,
  getLiveRadioLink,
  getMyMusicLink,
  getMyStationsLink,
  getNewsLink,
  getPhotosLink,
  getPlaylistsLink,
  getPodcastsLink,
  getUpgradeLink,
  getYourLibraryLink,
} from 'state/Links/selectors';
import {
  getCustomRadioEnabled,
  getInternationalPlaylistRadioEnabled,
} from 'state/Features/selectors';
import { localize } from 'redux-i18n';
import {
  showPlaylistSelector,
  showUpgradeButtonSelector,
} from 'state/Entitlements/selectors';
import { withRouter } from 'react-router-dom';
import type { Connected } from './NavItems';
import type { State } from 'state/types';

export default flowRight(
  withRouter,
  localize('translate'),
  connect(
    createStructuredSelector<State, Connected>({
      customRadioEnabled: getCustomRadioEnabled,
      hasUpgradeBtn: showUpgradeButtonSelector,
      internationalPlaylistRadioEnabled: getInternationalPlaylistRadioEnabled,
      navLinks: createStructuredSelector({
        contest: getContestsLink,
        custom: getCustomRadioLink,
        events: getEventsLink,
        features: getFeaturesLink,
        forYou: getForYouLink,
        genre: getGenresLink,
        home: getHomeLink,
        live: getLiveRadioLink,
        myMusic: getMyMusicLink,
        myStations: getMyStationsLink,
        news: getNewsLink,
        photo: getPhotosLink,
        playlist: getPlaylistsLink,
        podcast: getPodcastsLink,
        yourLibrary: getYourLibraryLink,
      }),
      showPlaylists: showPlaylistSelector,
      upgrade: getUpgradeLink,
    }),
  ),
)(NavItems);
