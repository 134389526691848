import styled from '@emotion/styled';

const Item = styled('li')(({ theme }) => ({
  color: theme.colors.gray.medium,
  display: 'inline-block',
  fontSize: '1.6rem',
  lineHeight: '2.8',
  width: '100%',
}));

export default Item;
