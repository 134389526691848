import Mask from './primitives/Mask';
import ScrollHintContainer from './primitives/ScrollHintContainer';
import { Component, ReactNode } from 'react';
import { throttle } from 'lodash-es';

type Props = {
  children: ReactNode;
  throttleTime: number;
};

type State = {
  isAtBottom: boolean;
  isAtTop: boolean;
};

class ScrollHint extends Component<Props, State> {
  container: HTMLDivElement | null | undefined;

  static defaultProps = { throttleTime: 100 };

  state = {
    isAtBottom: false,
    isAtTop: false,
  };

  componentDidMount(): void {
    const { throttleTime } = this.props;
    this.throttledSetOverlayState = throttle(
      this.setOverlayState,
      throttleTime,
    );
    this.throttledSetOverlayState();
  }

  throttledSetOverlayState: () => void = () => null;

  setOverlayState = (): void => {
    if (!this.container) return;
    const { scrollTop, clientHeight, scrollHeight } = this.container;
    const scrollBottom = clientHeight + scrollTop;

    this.setState({
      isAtBottom: scrollHeight - scrollBottom < 1,
      isAtTop: scrollTop < 1,
    });
  };

  render() {
    const { isAtTop, isAtBottom } = this.state;

    return (
      <ScrollHintContainer
        onScroll={this.throttledSetOverlayState}
        ref={c => {
          this.container = c;
        }}
      >
        {this.props.children}
        <Mask direction="down" show={!isAtTop} />
        <Mask direction="up" show={!isAtBottom} />
      </ScrollHintContainer>
    );
  }
}

export default ScrollHint;
