import styled from '@emotion/styled';

const Items = styled('ul')({
  display: 'inline-block',
  listStyle: 'none',
  margin: 0,
  padding: 0,
  verticalAlign: 'middle',
  width: '100%',
});

export default Items;
