import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

const GetTheApp = styled(NavLink)(({ theme }) => ({
  backgroundColor: theme.colors.transparent.primary,
  border: '2px solid rgba(255, 255, 255, 0.5)',
  borderRadius: '0.5rem',
  color: theme.colors.white.primary,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: '1.6rem',
  height: '4.5rem',
  lineHeight: '4.5rem',
  marginBottom: '1.5rem',
  padding: '0 1.5rem',
  textAlign: 'center',
  verticalAlign: 'middle',
  width: '100%',

  '&:hover': {
    textDecoration: 'none',
  },
}));

export default GetTheApp;
