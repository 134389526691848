import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Bottom = styled('div')(({ theme }) => ({
  borderTop: `0.1rem solid ${theme.colors.gray.medium}`,
  maxWidth: '32.26rem',
  padding: '0 1.5rem',
  width: '100%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['370'])]: {
    maxWidth: '27.5rem',
  },
}));

export default Bottom;
