import styled from '@emotion/styled';

const Auth = styled('div')({
  alignSelf: 'center',
  margin: 0,

  'a:last-of-type': {
    marginRight: 0,
  },
  'button:first-of-type': {
    '&:after': {
      content: '" | "',
    },
  },
});

export default Auth;
