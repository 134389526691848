import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

const UserBadge = styled(NavLink)({
  display: 'inline-block',
  marginRight: '0.75rem',

  '.image': {
    width: '2.7rem',

    img: { borderRadius: '50%' },
  },
});

export default UserBadge;
