import styled from '@emotion/styled';

const LoginButton = styled('button')(({ theme }) => ({
  alignItems: 'center',
  background: theme.colors.transparent.primary,
  border: 0,
  display: 'flex',
  height: '2.85rem',
  justifyContent: 'center',
  marginRight: '1.3rem',
  padding: 0,
  verticalAlign: 'middle',
  width: '2.85rem',
}));

export default LoginButton;
