import styled from '@emotion/styled';

type Props = {
  justified?: boolean;
};

const LoginSection = styled('div')<Props>(({ justified = false }) => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: '1.6rem',
  justifyContent: justified ? 'end' : undefined,
  marginTop: 0,
  paddingBottom: '1.5rem',
  paddingTop: '1.5rem',
  'button:nth-of-type(2)': {
    '&:after': {
      content: '" | "',
    },
  },
}));

export default LoginSection;
