import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

const UpgradeButton = styled(NavLink)(({ theme }) => ({
  backgroundColor: theme.colors.red.primary,
  borderRadius: '0.5rem',
  color: theme.colors.white.primary,
  display: 'inline-block',
  fontSize: '1.6rem',
  height: '4.5rem',
  lineHeight: '4.5rem',
  margin: theme.dimensions.gutter,
  padding: '0 1.5rem',
  textAlign: 'center',
  width: 'calc(100% - 3rem)',

  '&:hover': {
    textDecoration: 'none',
  },
}));

export default UpgradeButton;
