import styled from '@emotion/styled';

const ScrollHintContainer = styled('div')({
  height: '100%',
  overflowY: 'scroll',
  width: '100%',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export default ScrollHintContainer;
