import styled from '@emotion/styled';

type Props = {
  open: boolean;
};

const Overlay = styled('div')<Props>(({ open, theme }) => ({
  backgroundColor: theme.colors.black.primary,
  height: '100vh',
  left: 0,
  opacity: open ? 0.6 : 0,
  position: 'fixed',
  top: 0,
  transition: 'opacity 300ms ease, visibility 300ms ease',
  visibility: open ? 'visible' : 'hidden',
  width: '100vw',
  zIndex: theme.zIndex.header + 1 /* Header is 113 */,
}));

export default Overlay;
