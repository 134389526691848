import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

const Login = styled(NavLink)(({ theme }) => ({
  background: theme.colors.transparent.primary,
  color: theme.colors.white.primary,
  cursor: 'pointer',
  marginRight: '0.5rem',

  '&:hover': {
    textDecoration: 'none',
  },
}));

export default Login;
