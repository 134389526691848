import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  show: boolean;
};

const Nav = styled('nav')<Props>(({ show, theme }) => ({
  background: theme.colors.gray.dark,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  left: 0,
  margin: `0 0 0 ${show ? '0' : '-32.26rem'}`,
  maxWidth: '32.26rem',
  position: 'fixed',
  top: 0,
  transition: 'margin 0.5s',
  width: '100%',
  zIndex: 114,

  [mediaQueryBuilder(theme.mediaQueries.max.width['370'])]: {
    maxWidth: '26.26rem',
  },
}));

export default Nav;
