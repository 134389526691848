import AccountIcon from 'styles/icons/AccountIcon';
import Auth from './primitives/Auth';
import Bottom from './primitives/Bottom';
import GetTheApp from './primitives/GetTheApp';
import Login from './primitives/Login';
import LoginButton from './primitives/LoginButton';
import LoginSection from './primitives/LoginSection';
import Logo from './primitives/Logo';
import Nav from './primitives/Nav';
import NavItems from './NavItems';
import Overlay from './primitives/Overlay';
import ProfilePic from 'components/ProfilePic/ProfilePic';
import ScrollHint from './ScrollHint';
import SignUp from './primitives/SignUp';
import theme from 'styles/themes/default';
import UserBadge from './primitives/UserBadge';
import Username from './primitives/Username';
import { AuthModals } from 'components/Auth/constants';
import { PureComponent } from 'react';
import { throttle } from 'lodash-es';
import type { IGetTranslateFunctionResponse } from 'redux-i18n';

const SHOW_MOBILE_MENU_WIDTH = 768;

export type Connected = {
  appsMobileLink: string;
  displayName: string;
  isLoggedOut: boolean;
  showLoginInNav: boolean;
  showingSideNav: boolean;
};

type Props = Connected & {
  hideSideNav: () => void;
  openAuthModal: (modal: AuthModals) => void;
  logoutAndStartAnonymousSession: ({
    forced,
    noRedirect,
  }: {
    forced: boolean;
    noRedirect: boolean;
  }) => Promise<void>;
  translate: IGetTranslateFunctionResponse;
};

class SideNav extends PureComponent<Props> {
  state = { initialRender: true };

  handleResize = throttle(() => {
    const { hideSideNav, showingSideNav } = this.props;
    if (
      __CLIENT__ &&
      showingSideNav &&
      window.innerWidth >= SHOW_MOBILE_MENU_WIDTH
    ) {
      hideSideNav();
    }
  }, 300);

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps: Props) {
    const { showingSideNav } = this.props;

    if (showingSideNav !== prevProps.showingSideNav) {
      this.updateScrollOnBody(showingSideNav);
    }

    if (this.state.initialRender) {
      this.setState({ initialRender: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onLoginClick = () => {
    const { openAuthModal } = this.props;

    this.closeNav();
    openAuthModal(AuthModals.Login);
  };

  onSignupClick = () => {
    this.closeNav();
    this.props.openAuthModal(AuthModals.Signup);
  };

  onLogoutClick = () => {
    this.closeNav();
    this.props.logoutAndStartAnonymousSession({
      forced: false,
      noRedirect: false,
    });
    return true;
  };

  onAppsMobileClick = () => true;

  onSettingsClick = () => {
    this.closeNav();
    return true;
  };

  onProfileNameClick = () => {
    this.closeNav();
    return true;
  };

  onProfileIconClick = () => {
    this.closeNav();
    return true;
  };

  closeNav = () => {
    this.props.hideSideNav();
    return true;
  };

  updateScrollOnBody = (showingSideNav: boolean) => {
    if (showingSideNav) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  };

  render() {
    const {
      displayName,
      isLoggedOut,
      showingSideNav,
      showLoginInNav,
      appsMobileLink,
      translate,
    } = this.props;

    const { initialRender } = this.state;

    let loginSection;

    if (!isLoggedOut && !initialRender) {
      loginSection = (
        <LoginSection justified>
          <UserBadge to="/my/settings/">
            <span onClick={this.onProfileIconClick}>
              <ProfilePic size={25} />
            </span>
          </UserBadge>
          <Username to="/my/settings/">
            <span onClick={this.onProfileNameClick}>{displayName}</span>
          </Username>
          <Auth>
            <Login onClick={this.onLogoutClick}>{translate('Log Out')}</Login>
            <Login onClick={this.onSettingsClick} to="/my/settings/">
              {translate('Settings')}
            </Login>
          </Auth>
        </LoginSection>
      );
    } else {
      loginSection = (
        <LoginSection>
          <LoginButton
            onClick={this.onLoginClick}
            title="Account"
            type="button"
          >
            <AccountIcon color={theme.colors.white.primary} />
          </LoginButton>
          <Login onClick={this.onLoginClick} title="Log In">
            {translate('Log In')}
          </Login>
          <SignUp onClick={this.onSignupClick} title="Sign Up">
            {translate('Sign Up')}
          </SignUp>
        </LoginSection>
      );
    }

    return (
      <>
        <Overlay
          data-test="side-nav-overlay"
          onClick={this.closeNav}
          open={showingSideNav}
        />
        <Nav data-test="side-nav" show={showingSideNav}>
          <Logo onClick={this.closeNav} />
          <ScrollHint>
            <NavItems closeNav={this.closeNav} initialRender={initialRender} />
          </ScrollHint>
          {showLoginInNav && (
            <Bottom>
              {loginSection}
              <GetTheApp
                onClick={this.onAppsMobileClick}
                target="_blank"
                to={appsMobileLink}
              >
                <span>{translate('Get The App')}</span>
              </GetTheApp>
            </Bottom>
          )}
        </Nav>
      </>
    );
  }
}

export default SideNav;
