import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

const SignUp = styled(NavLink)(({ theme }) => ({
  color: theme.colors.white.primary,
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'none',
  },
}));

export default SignUp;
