import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

const Link = styled(NavLink)(({ theme }) => ({
  color: theme.colors.gray.light,
  display: 'inline-block',
  margin: 0,
  paddingLeft: `calc(${theme.dimensions.gutter} * 2 + 0.6rem)`,
  width: '100%',

  '&.active': {
    background: theme.colors.black.primary,
    borderLeft: `0.6rem solid ${theme.colors.red.primary}`,
    color: theme.colors.white.primary,
    paddingLeft: '3rem',
  },

  '&:hover': {
    textDecoration: 'none',
  },
}));

export default Link;
