import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

const Username = styled(NavLink)(({ theme }) => ({
  ...theme.mixins.ellipsis,
  color: theme.colors.white.primary,
  display: 'inline-block',
  marginRight: 0,
  verticalAlign: 'middle',
  maxWidth: '11.5rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['370'])]: {
    marginRight: '1rem',
    maxWidth: '5.3rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.min.width['375'])]: {
    marginRight: '1rem',
  },
}));

export default Username;
